import './jquery-global';

import $ from 'jquery';
import 'popper.js';
import 'bootstrap';

$(() => {
	const $window = $(window);
	const $header = $('#header');
	const $logo = $('#logo');
	const $logoImg = $('#logo-img');
	const $logoImgSml = $('#logo-img-sml');

	const animateDuration = 50;
	const logoPadding = 8;
	const logoSmlRatio = 226 / 490;

	let headerHeight;
	let logoWidth;
	let logoHeight;
	let mediaLgUp;

	const getSizing = () => {
		mediaLgUp = window.matchMedia('(min-width: 992px)').matches;
		headerHeight = $header.outerHeight();
		if (!$logo.hasClass('logo-fixed')) {
			logoWidth = $logo.outerWidth();
			logoHeight = $logo.outerHeight();
		}
	};
	getSizing();

	const animateLogo = () => {
		if (mediaLgUp) {
			if ($window.scrollTop() > logoHeight - headerHeight - logoPadding) {
				if (!$logo.hasClass('logo-fixed')) {
					$logo
						.css({
							top: -(logoHeight - headerHeight - logoPadding),
							width: logoWidth,
							height: logoHeight,
						})
						.addClass('logo-fixed');
					$logoImg.fadeOut(animateDuration, () => {
						$logoImg.css('display', 'none');
						$logo.addClass('logo-sml');
						const logoSmlHeight = headerHeight - logoPadding;
						const logoSmlWidth = logoSmlHeight / logoSmlRatio;
						$logoImgSml
							.css({
								height: logoSmlHeight,
								width: logoSmlWidth,
							})
							.fadeIn(200);
					});
				}
			} else if ($logo.hasClass('logo-fixed')) {
				$logo
					.css({
						top: 'auto',
						width: '100%',
						height: 'auto',
					})
					.removeClass('logo-fixed');
				$logoImgSml.fadeOut(animateDuration, () => {
					$logoImgSml.css('display', 'none');
					$logo.removeClass('logo-sml');
					$logoImg.fadeIn(200);
				});
			}
		}
	};
	animateLogo();

	$window.on('resize', () => {
		getSizing();
		animateLogo();
	});

	$window.on('scroll', animateLogo);

	const $homeVideo = $('#home-video');
	const $playVideo = $('#play-video');
	$playVideo.on('click', () => {
		if ($homeVideo.get(0).paused) {
			$homeVideo.trigger('play');
		} else {
			$homeVideo.trigger('pause');
		}
		return false;
	});
});
